export const GROUPS = {
  RX: {
    longName:
      'RX - Magnetic Reconnection in the Age of the Heliophysics System Observatory',
    hosts: 'Rick Wilder, Shan Wang, Michael Shay, and Anton Artemyev',
    blocks: ['monday-2', 'monday-3', 'tuesday-3', 'wednesday-1'],
    slack: 'gemworkshop.slack.com/app_redirect?channel=reconnection-rx',
  },
  RB: {
    longName:
      'RB - System Understanding of Radiation Belt Particle Dynamics through Multi-spacecraft and Ground-based Observations and Modeling',
    hosts: 'Hong Zhao, Lauren Blum, Sasha Ukhorskiy, and Xiangrong Fu',
    blocks: [
      'tuesday-1',
      'tuesday-2',
      'tuesday-3',
      'wednesday-2',
      'thursday-3',
    ],
    slack: 'gemworkshop.slack.com/app_redirect?channel=radiation-belt-rb',
  },
  BSH: {
    longName:
      'BSH - Particle Heating and Thermalization in Collisionless Shocks in the MMS Era',
    hosts: 'Lynn Wilson III, Li-Jen Chen, Kathrine Goodrich, Ivan Vasko',
    blocks: ['thursday-2', 'thursday-3'],
    slack: 'gemworkshop.slack.com/app_redirect?channel=collisionless-shock-bsh',
  },
  CP: {
    longName:
      'CP - The Impact of the Cold Plasma in the Magnetospheric Physics',
    hosts:
      'Gian Luca Delzanno, Natalia Buzulukosva, Barbara Giles, Roger Varney, Joe Borovsky',
    blocks: ['monday-2', 'monday-3', 'thursday-2', 'thursday-3', 'friday-1'],
    slack: 'gemworkshop.slack.com/app_redirect?channel=cold-plasma-cp',
  },
  SCIMM: {
    longName: 'SCIMM - Self-Consistent Inner Magnetospheric Modeling',
    hosts: 'Cristian Ferradas, Chao Yue, Jacob Bortnik, Qianli Ma',
    blocks: ['monday-1', 'thursday-2', 'thursday-3'],
    slack: 'gemworkshop.slack.com/app_redirect?channel=inner-mag-model-imm',
  },
  GIC: {
    longName:
      'GIC - Understanding the causes of geomagnetic disturbances in geospace for hazard analysis on geomagnetically induced currents',
    hosts:
      'Xueling Shi, Dogacan Oztuk, Mark Engebretson, Zhonghua Xu, Joshua Rigler',
    blocks: ['monday-1', 'monday-2', 'wednesday-2', 'thursday-1'],
    slack:
      'gemworkshop.slack.com/app_redirect?channel=geomagnetic-disturbances-gic',
  },
  MESO: {
    longName:
      'MESO - Mesoscale drivers of the nightside transition region: Ionospheric and magnetotail evaluations',
    hosts:
      'Bea Gallardo-Lacourt, Gareth Perry, Emma Spanswick, Yari Collado-Vega, Bashi Ferdousi',
    blocks: [
      'tuesday-1',
      'wednesday-2',
      'thursday-1',
      'thursday-2',
      'thursday-3',
      'friday-1',
    ],
    slack: 'gemworkshop.slack.com/app_redirect?channel=mesoscale-drivers-meso',
  },
  MPEC: {
    longName:
      'MPEC - Magnetospheric sources of particle precipitation and their role on electrodynamic coupling of magnetosphere-ionosphere-thermosphere systems',
    hosts:
      'Dogacan Su Ozturk, Dong Lin, Yiqun Yu, Katherine Garcia-Sage, Stephen Kaeppler',
    blocks: [
      'tuesday-2',
      'tuesday-3',
      'wednesday-1',
      'wednesday-2',
      'friday-1',
    ],
    slack:
      'gemworkshop.slack.com/app_redirect?channel=precipitation-conductance-mpec',
  },
  CPMP: {
    longName: 'CPMP - Comparative Planetary Magnetospheric Processes',
    hosts:
      'George Clark, Wen Li, Bob Marshall, Dan Gershman, Peter Delamere, Shannon Curry',
    blocks: ['tuesday-1', 'tuesday-2', 'tuesday-3', 'wednesday-1'],
  },
  KiTS: {
    longName:
      'KiTS - Kinetic Plasma Processes in the MagnetoTail during Substorm Dynamics',
    hosts: 'Harry Arnold, Jason Derr, Akhtar Ardakani, Anton Artemyev',
    blocks: ['monday-1', 'wednesday-1', 'wednesday-2', 'thursday-2'],
  },
  MLGEM: {
    longName:
      'MLGEM - Machine Learning as it pertains in the Geospace Environment',
    hosts: 'Matthew Argall',
    blocks: ['thursday-1', 'thursday-3', 'friday-1', 'friday-2'],
    slack: 'gemworkshop.slack.com/app_redirect?channel=machine-learning',
  },
  MMV: {
    longName: 'MMV - Modeling Methods and Validation',
    hosts: 'Mike Liemohn, Lutz Rastaetter, Alexa Halford, Josh Rigler',
    blocks: ['monday-3', 'thursday-1'],
  },
  GMAG: {
    longName: 'GMAG - Ground Based Matnetometer Array',
    hosts: 'Jenn Gannon, Michelle Salzano',
    blocks: ['friday-3'],
  },
  SPEDAS: {
    longName: 'SPEDAS - Space Physics Environment Data Analysis System',
    hosts: 'Jim Lewis',
    blocks: ['friday-3'],
  },
  SBCCI: {
    longName: 'SBCCI - The Sam Bingham Community Cares Initiative',
    subcommittee: ['GEM Steering Committee'],
    blocks: ['wednesday-3'],
    slack:
      'https://gemworkshop.slack.com/app_redirect?channel=0-sbcci-support-each-other',
  },
}
