import React, { useState } from 'react'
import styles from '../Style.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faReply, faPenSquare } from '@fortawesome/free-solid-svg-icons'
import InputField from './InputField'
import Avatar from 'boring-avatars'

const CommentStructure = ({ comment, posterId, name, email, ...props }) => {
  let [replyShowing, setReplyShowing] = useState(false)
  let [editShowing, setEditShowing] = useState(false)
  let toggleReply = () => setReplyShowing(!replyShowing)
  let toggleEdit = () => setEditShowing(!editShowing)
  let lettersOnly =
    comment && comment.name
      ? comment.name.replaceAll('[^A-Za-z]+', '').toLowerCase()
      : '.'
  return (
    <div>
      {comment && (
        <div className={styles.halfDiv}>
          <div
            className={styles.userInfo}
            style={{
              marginLeft: 15,
              marginTop: '6px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div className={styles.avatar}>
              <Avatar
                size={30}
                name={lettersOnly}
                variant='beam'
                colors={['#FFBC17', '#1BB0CE', '#346D7C', '#6E2BB5', '#309130']}
              />{' '}
            </div>
            <div className={styles.content}>
              <div className={styles.topLine}>
                <div className={styles.fullName}>{comment.name} </div>
                <button
                  className={styles.replyBtn}
                  onClick={() => {
                    toggleReply()
                  }}
                >
                  {' '}
                  <FontAwesomeIcon
                    icon={faReply}
                    size='1x'
                    color='#a5a5a5'
                  />{' '}
                  Reply
                </button>
                {email === comment.email && (
                  <button
                    className={styles.replyBtn}
                    onClick={() => {
                      toggleEdit()
                    }}
                  >
                    {' '}
                    <FontAwesomeIcon
                      icon={faPenSquare}
                      size='1x'
                      color='#a5a5a5'
                    />{' '}
                    Edit
                  </button>
                )}
              </div>
              <div className={styles.textLine}>
                <div>{comment.text}</div>
              </div>
            </div>
          </div>
        </div>
      )}
      {replyShowing && (
        <InputField
          parentId={comment.id}
          handleToggle={() => toggleReply()}
          posterId={posterId}
          name={name}
          email={email}
        />
      )}
      {editShowing && (
        <InputField
          parentId={comment.id}
          handleToggle={() => toggleEdit()}
          posterId={posterId}
          name={name}
          email={email}
          comment={comment}
        />
      )}
    </div>
  )
}

export default CommentStructure
