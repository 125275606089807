const ROOMS = {
  S3: { title: 'Salon III, IV, VI', displayShort: 'S346' },
  GG: { title: 'Gold & Green', displayShort: 'G&G' },
  A: { title: 'Atrium', displayShort: 'A' },
  RAMS: { title: 'RAMS', displayShort: 'RAMS' },
  S1: { title: 'Salon I, II, V', displayShort: 'S125' },
  L: { title: 'Legends', displayShort: 'L' },
  CPB: { title: `CSU President's Boardroom`, displayShort: 'CPB' },
}

export { ROOMS }
