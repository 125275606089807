import React, { useState, useEffect, useContext } from 'react'
import { Button, Table, Form, Modal } from 'react-bootstrap'
import { AuthUserContext, withAuthentication } from '../Session/'
import { POSTER_CATEGORIES } from '../../constants/posterSched.js'
import SlackLink from '../SlackLink'
//import posterOrder from '../../constants/posterOrder'
import { AiOutlineProject, AiOutlineVideoCamera } from 'react-icons/ai'
import uploadingGif from '../../imgs/uploading.gif'
import { useParams, useSearchParams } from 'react-router-dom'
import { FirebaseContext } from '../../server/Firebase'
import FilterBar from './FilterBar'
import { NavLink } from 'react-router-dom'
const Posters = () => {
  const firebase = useContext(FirebaseContext)
  const authUser = useContext(AuthUserContext)
  const [searchParams] = useSearchParams()
  const [researchAreas, setResearchAreas] = useState(
    [...searchParams].filter((e) => e[0] === 'researchArea').map((e) => e[1])
  )
  const [uploadedFiles, setUploadedFiles] = useState(
    [...searchParams].filter((e) => e[0] === 'hasFiles').map((e) => e[1])
  )
  useEffect(() => {
    setResearchAreas(
      [...searchParams].filter((e) => e[0] === 'researchArea').map((e) => e[1])
    )
    setUploadedFiles(
      [...searchParams].filter((e) => e[0] === 'hasFiles').map((e) => e[1])
    )
  }, [searchParams])

  const postersDb = firebase.usePosters({})

  const presenter = searchParams.get('presenter')
  let posters = postersDb

  if (researchAreas.length) {
    posters = posters.filter((p) => researchAreas.includes(p.researchArea))
  }

  if (presenter) {
    posters = posters.filter((p) =>
      p.fullname.toLowerCase().includes(presenter.toLowerCase())
    )
  }
  const title = searchParams.get('title')
  if (title) {
    posters = posters.filter((p) =>
      p.postertitle.toLowerCase().includes(title.toLowerCase())
    )
  }
  const days = [...searchParams].filter((e) => e[0] === 'day').map((e) => e[1])
  if (days.length) {
    posters = posters.filter((p) => days.includes(p.day.toLowerCase()))
  }

  if (uploadedFiles.includes('PDF')) {
    posters = posters.filter((p) => !!p.pdfUrl)
  }
  if (uploadedFiles.includes('Video')) {
    posters = posters.filter((p) => !!p.videoUrl)
  }

  const number = searchParams.get('number')
  if (number) {
    posters = posters.filter((p) => p.number.toString().includes(number))
  }
  return (
    <div>
      <UploadPosterButton
        posters={postersDb.filter((poster) => poster.email === authUser.email)}
      />
      <h1 className='text-center py-3 text-5xl'>Posters</h1>
      <FilterBar></FilterBar>
      <br />
      <br />
      {Object.keys(POSTER_CATEGORIES).map((section) => (
        <div key={section}>
          <ResearchAreaTemplate
            researchArea={section}
            key={section}
            posterList={posters.filter(
              (poster) => poster.researchArea === section
            )}
          />
        </div>
      ))}
    </div>
  )
}

const UploadPosterButton = ({ posters, posterInfo = {} }) => {
  const [showModal, setShowModal] = useState(false)

  const handleToggle = () => setShowModal(!showModal)
  return (
    <div>
      <Button onClick={() => handleToggle()}>Upload your poster</Button>
      <Modal
        show={showModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={true}
        onHide={handleToggle}
        title='Upload'
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Upload Poster Files
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {posters.map((poster) => {
            return (
              <div key={poster.id}>
                <UploadPosterSection
                  poster={poster}
                  startOpen={posters.length === 1}
                  closeModal={handleToggle}
                />
                <br />
                <hr />
                <br />
              </div>
            )
          })}
          <p>
            If you have any issues uploading your poster, please email me
            vgemhelp@gmail.com. Please include your name, poster title, and
            email you used to log into this website.
          </p>
        </Modal.Body>
      </Modal>
    </div>
  )
}
const UploadPosterSection = ({ poster, startOpen = false, closeModal }) => {
  const [showSection, setShowSection] = useState(startOpen)
  const [pdfFile, setPdfFile] = useState(null)
  const [videoFile, setVideoFile] = useState(null)
  const [errors, setErrors] = useState({})
  const [isUploading, setIsUploading] = useState(false)
  const firebase = useContext(FirebaseContext)
  const handleToggle = () => setShowSection(!showSection)
  const findFormErrors = () => {
    let newErrors = {}
    if (!poster.pdfUrl && pdfFile === null)
      newErrors.pdf = 'You need to upload your poster as a PDF'
    return newErrors
  }

  return (
    <div>
      <Button onClick={() => handleToggle()}>{poster.postertitle}</Button>
      <br />
      <br />
      <div style={{ display: showSection ? '' : 'none' }}>
        <Form
          onSubmit={async (e) => {
            e.preventDefault()
            let newErrors = findFormErrors()
            if (Object.keys(newErrors).length) {
              setErrors(newErrors)
            } else {
              setIsUploading(true)
              await firebase.updatePoster({ data: poster, pdfFile, videoFile })
              setIsUploading(false)
              setShowSection(false)
              if (startOpen) closeModal()
            }
          }}
        >
          <Form.Group>
            <Form.Label>Your Poster (must be a PDF)</Form.Label>
            <Form.Control
              type='file'
              id='pdfFile'
              label='Your Poster (must be a PDF)'
              onChange={(e) => setPdfFile(e.target.files[0])}
              accept='.pdf'
            />
            {!!errors.pdf && <div style={{ color: 'red' }}>{errors.pdf}</div>}
          </Form.Group>
          <Form.Group>
            <Form.Label>Your Video (mp4)</Form.Label>
            <Form.Control
              type='file'
              id='videoFile'
              label='Your Video (mp4)'
              onChange={(e) => setVideoFile(e.target.files[0])}
              accept='.mp4'
            />
            <Form.Text>
              Video is optional, but recommended. If you have only audio, please
              make it a video by playing it on top of an image of your poster.
            </Form.Text>
          </Form.Group>
          You can reupload your files at any time. The files you had previously
          uploaded will be overwritten.
          <br />
          <br />
          <Button variant='success' type='submit'>
            Submit
          </Button>
          <img
            src={uploadingGif}
            style={{ display: isUploading ? '' : 'none' }}
            alt='Spinning circle around the word uploading'
          ></img>
        </Form>
      </div>
    </div>
  )
}

const Section = ({ section }) => {
  const firebase = useContext(FirebaseContext)
  const authUser = useContext(AuthUserContext)
  let researchArea = section
  let posterList = firebase.usePosters({ researchArea })
  return (
    <div>
      <br />
      <h1>{section}</h1>
      <UploadPosterButton
        posters={posterList.filter((poster) => poster.email === authUser.email)}
        firebase={firebase}
      />
      <div
        style={{
          alignItems: 'center',
          textAlign: 'center',
          backgroundColor: 'gray',
        }}
      >
        {/* <Carousel>
          {shuffled.map((poster) => {
            return (
              <Carousel.Item key={poster.id}>
              <a
              href={`/posters/${poster.id}`}
              style={{ textAlign: 'center' }}
              >
              <img
              src={poster.src}
              alt='Poster'
              height='400'
              margin='auto'
              ></img>
              </a>
              </Carousel.Item>
              )
            })}
          </Carousel> */}
      </div>
      <br />
      <ResearchAreaTemplate
        researchArea={researchArea}
        posterList={posterList}
      ></ResearchAreaTemplate>
    </div>
  )
}

const ResearchAreaTemplate = ({ researchArea, posterList }) => {
  if (!posterList || posterList.length === 0) {
    return
  }
  return (
    <div>
      <h4 className={'left'}>
        <strong>{POSTER_CATEGORIES[researchArea]} </strong>
        <SlackLink url={'gemworkshop.slack.com/messages/0-poster'} />
      </h4>
      <Table>
        <thead>
          <tr>
            <th>Presenter</th>
            <th>Title</th>
            <th>Day</th>
            <th></th>
            <th>PDF</th>
          </tr>
        </thead>
        <tbody>
          {posterList.map((poster, ind) => {
            const name = poster.fullname
            const title = poster.postertitle

            return (
              <tr key={ind}>
                <td>{name}</td>
                <td>
                  <NavLink to={`/posters/${poster.id}`}>{title}</NavLink>
                </td>
                <td>{poster.day}</td>
                <td>{poster.number}</td>
                <td>
                  {poster['pdfUrl'] ? (
                    <AiOutlineProject
                      size='30'
                      color='green'
                      title='Upload Completed'
                    />
                  ) : (
                    <AiOutlineProject
                      size='30'
                      color='#F1948A'
                      title='No Poster'
                    />
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
      <br />
    </div>
  )
}

const PostersSectionPre = () => {
  let { sectionId } = useParams()
  return <Section section={sectionId} />
}
const PostersSection = withAuthentication(PostersSectionPre)
export { PostersSection }

export default withAuthentication(Posters)
