import React, { useState, useContext } from 'react'
import { AuthUserContext, withAuthentication } from '../Session'
import { FirebaseContext } from '../../server/Firebase'
import { Table, Button, Form, Modal } from 'react-bootstrap'
//import * as ROLES from '../../constants/roles'
import { studentSession } from '../../constants/studentSession'
import SlackLink from '../SlackLink'
import uploadingGif from '../../imgs/uploading.gif'
import { AiOutlineCloudUpload, AiOutlineCloudDownload } from 'react-icons/ai'
import { BsFileEarmarkArrowDown, BsFileEarmarkPlus } from 'react-icons/bs'
import MeetingLink from '../MeetingLink'
import MiscVideoModal from '../MiscVideoModal'

const StudentDay = (props) => {
  const firebase = useContext(FirebaseContext)
  const presentations = Object.keys(studentSession.blocks)
  const fbPresentations = firebase.useTalks({ splinterGroup: 'student' })
  const meeting = firebase.useMiscMeeting({ session: 'student' })
  const user = useContext(AuthUserContext)
  return (
    <div>
      <h1 className='text-center py-3 text-5xl'>Student Day Schedule</h1>
      <h3 className='text-center py-3 text-3xl'>{studentSession.date}</h3>
      <h4
        className='text-center '
        // style={{
        //   display: 'flex',
        //   justifyContent: 'space-between',
        //   width: '30%',
        //   margin: 'auto',
        // }}
      >
        <a
          href='https://gem.epss.ucla.edu/mediawiki/index.php/GEM_Student_Forum'
          target='_blank'
          rel='noopener noreferrer'
          title='Student Wiki'
          className='text-blue-500 hover:text-red-500'
        >
          Wiki
        </a>
      </h4>
      <div className='flex justify-center pb-2'>
        {meeting?.link && (
          <p className='flex gap-2'>
            Join us remotely!{' '}
            {<MeetingLink link={meeting.link} info={meeting.linkInfo} />}
          </p>
        )}
        {user?.isAdmin && (
          <MiscVideoModal
            session='student'
            initialVideoLink={meeting?.link}
            initialVideoInfo={meeting?.linkInfo}
          />
        )}
      </div>
      <Table bordered hover size='lg'>
        <thead>
          <tr>
            <th width='125px'>PT Time</th>
            <th>Presentation</th>
            <th>Speaker</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {presentations.map((block) => (
            <Block
              block={block}
              fbPresentations={fbPresentations}
              firebase={props.firebase}
              key={block}
            />
          ))}
        </tbody>
      </Table>
    </div>
  )
}

const Block = ({ block, fbPresentations, firebase, ...props }) => {
  const presentation = studentSession.blocks[block]
  let row = (
    <tr>
      <td rowSpan={1}>
        {presentation.time}
        <h3>{/* <VideoLink url={'student/video'} /> */}</h3>
      </td>
      <td>{presentation.name}</td>
      <td>
        {presentation.host &&
          (presentation.hostLink ? (
            <a
              href={presentation.hostLink}
              target='_blank'
              rel='noopener noreferrer'
              title={`Info about ${presentation.host}`}
            >
              {presentation.host}
            </a>
          ) : (
            presentation.host
          ))}
      </td>
      <td>
        {presentation.host && (
          <UploadButton
            presentation={
              fbPresentations[presentation.name]
                ? fbPresentations[presentation.name]
                : presentation
            }
            firebase={firebase}
            block={block}
          />
        )}
      </td>
      <td>
        {fbPresentations[presentation.name] && (
          <a
            href={fbPresentations[presentation.name].url}
            target='_blank'
            rel='noopener noreferrer'
            download
            style={{ cursor: 'pointer' }}
            title='Download this presentation'
          >
            {<BsFileEarmarkArrowDown size='30px' />}
          </a>
        )}
      </td>
    </tr>
  )
  return row
}

// const ElectionBlock = ({
//   studentRepsDB = [],
//   studentRepsFS = {},
//   firebase,
//   ...props
// }) => {
//   return (
//     <div>
//       <h4 className={'left'}>Student Representative Election</h4>
//       <Table width='50%'>
//         <thead>
//           <tr>
//             <th>Candidate</th>
//             <th></th>
//             <th></th>
//             <th></th>
//             <th></th>
//           </tr>
//         </thead>
//         <tbody>
//           {studentRepsDB.map((rep) => (
//             <tr key={rep.name}>
//               <td>
//                 <a href={`/student/${rep.id}`}>{rep.name}</a>
//               </td>
//               <td>
//                 <UploadButtonElect
//                   rep={studentRepsFS[rep.id] ? studentRepsFS[rep.id] : rep}
//                   firebase={firebase}
//                 />
//               </td>
//               <td>
//                 {studentRepsFS[rep.id] && studentRepsFS[rep.id].bio ? (
//                   <AiOutlineProfile
//                     size='30'
//                     fill='green'
//                     title='Upload Completed'
//                     color='green'
//                   />
//                 ) : (
//                   <AiOutlineProfile
//                     size='30'
//                     fill='#F1948A'
//                     color='#F1948A'
//                     title='No Bio'
//                   />
//                 )}
//               </td>
//               <td>
//                 {studentRepsFS[rep.id] && studentRepsFS[rep.id].url ? (
//                   <AiOutlineVideoCamera
//                     size='30'
//                     title='Upload Completed'
//                     color='green'
//                   />
//                 ) : (
//                   <AiOutlineVideoCamera
//                     size='30'
//                     color='#F1948A'
//                     title='No Video'
//                   />
//                 )}
//               </td>
//               <td>
//                 {studentRepsFS[rep.id] && studentRepsFS[rep.id].pdfURL ? (
//                   <AiOutlineFilePdf
//                     size='30'
//                     fill='green'
//                     title='Upload Completed'
//                     color='green'
//                   />
//                 ) : (
//                   <AiOutlineFilePdf
//                     size='30'
//                     fill='#F1948A'
//                     color='#F1948A'
//                     title='No PDF'
//                   />
//                 )}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   )
// }

const DownloadButton = ({ url, ...props }) => {
  return (
    <a
      href={url}
      download
      target='_blank'
      rel='noopener noreferrer'
      title='Download'
    >
      {<AiOutlineCloudDownload size='30' />}
    </a>
  )
}

const UploadButton = ({ presentation }) => {
  const firebase = useContext(FirebaseContext)
  const [showModal, setShowModal] = useState(false)
  const [slides, setSlides] = useState(null)
  const [verify, setVerify] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const handleToggle = () => setShowModal(!showModal)
  return (
    <div>
      <BsFileEarmarkPlus
        size='30'
        onClick={() => handleToggle()}
        title='Upload'
        style={{ cursor: 'pointer' }}
      />
      <Modal
        show={showModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={true}
        onHide={handleToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Upload Poster Files
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              setIsUploading(true)
              firebase.postStudentOrPlenaryTalk({
                group: 'student',
                data: presentation,
                file: slides,
                id: presentation.name,
              })
              setIsUploading(false)
              setShowModal(false)
            }}
          >
            <Form.Group controlId='verify'>
              <Form.Check
                id='verify'
                type='checkbox'
                checked={verify}
                onChange={() => setVerify(!verify)}
                label={`I confirm that I am ${presentation.host}.`}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type='file'
                id='posterFile'
                label='Please upload your presentation as a PDF. This will allow everyone to access and download this file.'
                onChange={(e) => setSlides(e.target.files[0])}
                accept='.pdf, .zip, .ppt, .pptx'
              />
            </Form.Group>
            You can reupload your files at any time. The files you had
            previously uploaded will be overwritten.
            <br />
            <br />
            <Button
              variant='primary'
              type='submit'
              disabled={!(verify && !!slides)}
            >
              Submit
            </Button>
            <img
              src={uploadingGif}
              style={{ display: isUploading ? '' : 'none' }}
              alt='Spinning circle around the word uploading'
            ></img>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const UploadButtonElect = ({ rep, firebase, ...props }) => {
  const [showModal, setShowModal] = useState(false)
  const [video, setVideo] = useState(null)
  const [bio, setBio] = useState('')
  const [pdf, setPDF] = useState(null)
  const [verify, setVerify] = useState(false)
  const handleToggle = () => setShowModal(!showModal)
  return (
    <div>
      <AiOutlineCloudUpload
        size='30'
        onClick={() => handleToggle()}
        title='Upload'
      />
      <Modal
        show={showModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={true}
        onHide={handleToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Upload Election Info
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={(e) => {
              e.preventDefault()
              firebase.uploadElection({ video, bio, data: rep, pdf })
              setShowModal(false)
            }}
          >
            <Form.Group controlId='verify'>
              <Form.Check
                id='verify'
                type='checkbox'
                checked={verify}
                onChange={() => setVerify(!verify)}
                label={`I confirm that I am ${rep.name}.`}
              />
            </Form.Group>
            <Form.Group controlId='bio'>
              <Form.Label>Please upload a short bio</Form.Label>
              <Form.Control
                as='textarea'
                rows='3'
                onChange={(e) => setBio(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.File
                id='videoFile'
                label='Please upload a short video'
                onChange={(e) => setVideo(e.target.files[0])}
              />
            </Form.Group>
            <Form.Group>
              <Form.File
                id='pdf'
                label='Optional PDF'
                onChange={(e) => setPDF(e.target.files[0])}
                accept='application/pdf'
              />
            </Form.Group>
            You can reupload your files at any time. The files you had
            previously uploaded will be overwritten.
            <br />
            <br />
            <Button variant='primary' type='submit' disabled={!verify}>
              Submit
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const condition = (authUser) => !!authUser

export default withAuthentication(StudentDay)
