import React, { Fragment, useState } from 'react'
import * as ROUTES from '../../constants/routes'
import SignOutButton from '../SignOut'
import { AuthUserContext } from '../Session'
import { GROUPS } from '../../constants/splinterGroups'
import NavDropdown from './NavDropdown'
import gemLogo from '../../imgs/gemLogo.png'
import { NavLink } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import Hamburger from './Hamburger'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/solid'

const Navigation = () => (
  <div>
    <AuthUserContext.Consumer>
      {(authUser) => (authUser ? <NavSignedIn /> : <NavSignedOut />)}
    </AuthUserContext.Consumer>
  </div>
)

const NavSignedIn = () => (
  <div className='flex justify-end sm:grid sm:grid-cols-[1fr_auto] gap-4 items-center w-full bg-[#1d73be] p-2 text-white'>
    <div className='hidden sm:flex items-center md:gap-2 lg:gap-4'>
      <NavLink className='hover:text-natural-300' to={ROUTES.LANDING}>
        Schedule
      </NavLink>
      <FocusGroupDropdown />
      <NavLink to={ROUTES.PLENARY}>Plenary</NavLink>
      <StudentDropdown />
      <NavLink className='navLink' to={ROUTES.POSTERS}>
        Posters
      </NavLink>
      <NavLink className='navLink' to={'conduct'}>
        Code of Conduct
      </NavLink>
      <a href='https://gemworkshop.org/'>Workshop Site</a>
    </div>
    <div className='hidden sm:block'>
      <SignOutButton />
    </div>
    <div className='block sm:hidden'>
      <HamburgerMenu />
    </div>
  </div>
)

const NavSignedOut = () => (
  <div className='flex justify-between items-center w-full bg-[#1d73be] p-2'>
    <Logo />
    <Button variant='light' href={ROUTES.SIGN_IN} as='button'>
      Sign In
    </Button>
  </div>
)

const Logo = () => (
  <NavLink to={ROUTES.LANDING}>
    <img src={gemLogo} height='40px' width='40px' alt='Gem Logo' />
  </NavLink>
)

const focusGroupOptions = Object.keys(GROUPS)
  .filter((groupId) => groupId !== 'SBCCI')
  .map((groupId) => {
    return {
      route: `${ROUTES.FOCUSGROUPS}/${groupId}`,
      name: groupId,
    }
  })
const FocusGroupDropdown = () => {
  return <NavDropdown title='Focus Groups' options={focusGroupOptions} />
}

const studentOptions = [
  { name: 'Student Day Schedule', route: ROUTES.STUDENTSCHEDULE },
  {
    name: 'Student Rep Candidates',
    route: '/studentRep',
  },
]
const StudentDropdown = () => {
  return <NavDropdown title='Student' options={studentOptions} />
}

const HamburgerMenu = () => {
  const [focusGroupOpen, setFocusGroupOpen] = useState(false)
  const [studentOpen, setStudentOpen] = useState(false)

  const NestedButton = (props) => {
    const { title, isOpen, setNewState } = props
    return (
      <button
        onClick={(e) => {
          e.preventDefault()
          setNewState()
        }}
      >
        {isOpen ? (
          <div className='flex align-items justify-between'>
            <ChevronLeftIcon className='h-5 w-5 mr-2' />
            {title}
          </div>
        ) : (
          <div className='flex align-items'>
            {title}
            <ChevronRightIcon className='h-5 w-5 mr-2' />
          </div>
        )}
      </button>
    )
  }

  return (
    <Menu>
      <MenuButton>
        <Hamburger />
      </MenuButton>
      <MenuItems
        anchor='bottom right'
        className='w-1/2 flex flex-col bg-[#61559f] p-2 rounded-lg text-white gap-2'
      >
        {focusGroupOpen && (
          <div className='contents'>
            <MenuItem>
              <NestedButton
                title='Focus Groups'
                isOpen={focusGroupOpen}
                setNewState={() => {
                  setFocusGroupOpen(!focusGroupOpen)
                  setStudentOpen(false)
                }}
              />
            </MenuItem>
            {focusGroupOptions.map((option) => (
              <NavLink to={option.route}>
                <MenuItem
                  className='bg-[#1d73be] p-2  text-white border-blue-900 border-t last:border-b block data-[focus]:bg-blue-300 rounded-lg'
                  as='div'
                >
                  <div>{option.name}</div>
                </MenuItem>
              </NavLink>
            ))}
          </div>
        )}
        {studentOpen && (
          <div className='contents'>
            <MenuItem>
              <NestedButton
                title='Students'
                isOpen={studentOpen}
                setNewState={() => {
                  setStudentOpen(!studentOpen)
                  setFocusGroupOpen(false)
                }}
              />
            </MenuItem>
            {studentOptions.map((option) => (
              <NavLink to={option.route}>
                <MenuItem className='bg-[#1d73be] p-2  text-white border-blue-900 first:border-t last:border-b block data-[focus]:bg-blue-300 rounded-lg'>
                  <div>{option.name}</div>
                </MenuItem>
              </NavLink>
            ))}
          </div>
        )}
        {!focusGroupOpen && !studentOpen && (
          <div className='contents'>
            <MenuItem className=''>
              <NavLink className='hover:text-natural-300' to={ROUTES.LANDING}>
                Schedule
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NestedButton
                title='Focus Groups'
                isOpen={focusGroupOpen}
                setNewState={() => {
                  setFocusGroupOpen(!focusGroupOpen)
                  setStudentOpen(false)
                }}
              />
            </MenuItem>
            <MenuItem as={Fragment}>
              <NavLink to={ROUTES.PLENARY}>Plenary</NavLink>
            </MenuItem>
            <MenuItem>
              <NestedButton
                title='Students'
                isOpen={studentOpen}
                setNewState={() => {
                  setStudentOpen(!studentOpen)
                  setFocusGroupOpen(false)
                }}
              />
            </MenuItem>
            <MenuItem as={Fragment}>
              <NavLink className='navLink' to={ROUTES.POSTERS}>
                Posters
              </NavLink>
            </MenuItem>
            <MenuItem as={Fragment}>
              <NavLink className='navLink' to={'conduct'}>
                Code of Conduct
              </NavLink>
            </MenuItem>
            <MenuItem as={Fragment}>
              <a href={'https://gemworkshop.org'}>Workshop Website</a>
            </MenuItem>
            <MenuItem>
              <SignOutButton />
            </MenuItem>
          </div>
        )}
      </MenuItems>
    </Menu>
  )
}

export default Navigation
