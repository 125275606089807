import React, { useContext, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from 'react-bootstrap/Dropdown'
import { FirebaseContext } from '../../server/Firebase'
import uploadingGif from '../../imgs/uploading.gif'
import { AuthUserContext } from '../Session'
import { GROUPS } from '../../constants/splinterGroups'
import { BLOCKS } from '../../constants/blocks'
import { FormCheck } from 'react-bootstrap'
import FormCheckInput from 'react-bootstrap/esm/FormCheckInput'
import FormCheckLabel from 'react-bootstrap/esm/FormCheckLabel'

const AddTalkModal = ({ splinterGroup, ...props }) => {
  const firebase = useContext(FirebaseContext)
  const user = useContext(AuthUserContext)
  const [showModal, setShowModal] = useState(false)
  const [title, setTitle] = useState('')
  const [name, setName] = useState(user.name)
  const [blockId, setBlockId] = useState('unscheduled')
  const [isPublic, setIsPublic] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const [slides, setSlides] = useState(null)
  const [showCompleted, setShowCompleted] = useState(false)
  const handleToggle = () => {
    setShowModal(!showModal)
    setShowCompleted(false)
  }

  const unscheduledBlock = {
    name: 'Unscheduled Talks',
    id: 'unscheduled',
    groups: [splinterGroup],
    rooms: [],
    time: '',
  }
  const blocks = [
    ...GROUPS[splinterGroup].blocks.map((blockId) => {
      const day = blockId.split('-')[0]
      return Object.values(BLOCKS[day]).find((block) => block.id === blockId)
    }),
    unscheduledBlock,
  ]
  return (
    <div>
      <Button type='button' variant='success' onClick={() => handleToggle()}>
        Add a talk!
      </Button>
      <Modal
        show={showModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={true}
        onHide={handleToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Add a {splinterGroup} Talk
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={async (e) => {
              e.preventDefault()
              setIsUploading(true)
              const block = blocks.find((block) => blockId === block.id)
              const splinterGroups = block.groups.includes(splinterGroup)
                ? [splinterGroup]
                : block.groups.find(
                    (elem) =>
                      (typeof elem !== 'string') & elem.includes(splinterGroup)
                  )
              await firebase.postTalk({
                splinterGroups: splinterGroups,
                data: {
                  name,
                  title,
                  isPublic,
                  email: user.email,
                },
                file: slides,
                blockId: blockId,
              })
              setIsUploading(false)
              setShowCompleted(true)
            }}
          >
            {!showCompleted && (
              <div>
                <Form.Group controlId='title'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Your Title'
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Group>
                <Form.Group controlId='name'>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    defaultValue={name}
                    type='text'
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Group>
                <br />
                <Form.Group>
                  <Form.Label>Presentation</Form.Label> <br />
                  <Form.Text>
                    Please upload as a ppt, pptx, or pdf. If your presentation
                    has videos, put your presentation and all necessary media in
                    a zipped file to upload. After uploading, please download
                    your presenation to make sure it works.
                  </Form.Text>
                  <Form.Control
                    type='file'
                    id='slidesFile'
                    label='Please upload your presentation'
                    accept='.ppt, .pptx, .pdf, .zip'
                    onChange={(e) => setSlides(e.target.files[0])}
                  />
                </Form.Group>
                <Form.Text className='text-muted'>
                  You can reupload your files at any time. The files you had
                  previously uploaded will be overwritten.
                </Form.Text>
                <br />
                <br />
                <Form.Group controlId='public'>
                  <Form.Check
                    id='public'
                    type='checkbox'
                    checked={isPublic}
                    onChange={() => setIsPublic(!isPublic)}
                    label={`I wish to make my presentation public, so that anyone can download it. If unchecked, it will be available to hosts only.`}
                  />
                </Form.Group>
                <br />
                {user.isAdmin && (
                  <ChooseBlockButton
                    blocks={blocks}
                    setBlockId={setBlockId}
                    splinterGroup={splinterGroup}
                  />
                )}
                <br />
                <Button
                  variant='primary'
                  type='submit'
                  disabled={!title.length}
                >
                  Submit
                </Button>{' '}
              </div>
            )}
            {isUploading && (
              <img
                src={uploadingGif}
                alt='Spinning circle around the word uploading'
              ></img>
            )}
            {showCompleted && (
              <span className='text-center'>
                <p>
                  Your talk has been successfully uploaded to the{' '}
                  {blockId.charAt(0).toUpperCase() +
                    blockId.slice(1).replace('-', ' ')}{' '}
                  Session
                </p>
                <br></br>
                <p>Your talk will be scheduled by a Focus Group Leader.</p>
              </span>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const ChooseTalkButton = ({
  blocks = [],
  splinterGroup,
  setBlockId = () => {},
}) => {
  return (
    <DropdownButton title='Choose Session' type='button'>
      {blocks.map((block) => {
        return (
          <Dropdown.Item
            as='button'
            type='button'
            name={block.id}
            key={splinterGroup + block.id}
            onClick={(event) => {
              setBlockId(event.target.name)
            }}
          >
            {block.name}
          </Dropdown.Item>
        )
      })}
    </DropdownButton>
  )
}
const ChooseBlockButton = ({
  blocks = [],
  splinterGroup,
  setBlockId = () => {},
}) => {
  return (
    <div>
      <div class='p-2 pl-0'>Choose Session</div>
      {blocks.map((block) => {
        return (
          <FormCheck type='radio' key={block.id}>
            <FormCheckInput
              type='radio'
              value={block.id}
              id={block.id + splinterGroup}
              name={'block'}
              onClick={(e) => {
                setBlockId(block.id)
              }}
            ></FormCheckInput>
            <FormCheckLabel>
              {block.id.charAt(0).toUpperCase() +
                block.id.slice(1).replace('-', ' ')}
            </FormCheckLabel>
          </FormCheck>
        )
      })}
    </div>
  )
}

export default AddTalkModal
