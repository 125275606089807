import React, { useState, useContext } from 'react'
import { FirebaseContext } from '../../server/Firebase'
import { DropdownButton, Dropdown, Modal, Button, Form } from 'react-bootstrap'
import { GROUPS } from '../../constants/splinterGroups'
import { ROOMS } from '../../constants/rooms'
import { NavLink } from 'react-router-dom'
import {
  BsCaretUpFill,
  BsCaretUp,
  BsCaretDown,
  BsCaretDownFill,
} from 'react-icons/bs'
import {
  PencilSquareIcon,
  ArrowDownOnSquareIcon,
} from '@heroicons/react/24/solid'
import * as ROUTES from '../../constants/routes'
import './OneBlockComponents.css'
import uploadingGif from '../../imgs/uploading.gif'
import { AuthUserContext } from '../Session'
import { TalksContext, QuestionsContext } from './context'
import AddVideoModal from './AddVideoModal'
import MeetingLink from '../MeetingLink'
import { AddQuestionModal, EditQuestionModal } from '../AddTalk/AddQuestion'
import QuestionsTable from './QuestionsTable'
import AddTalkModal from '../AddTalk'

let OneBlockComponent = ({
  block,
  splinterGroup = '',
  blocks = [],
  partners = {},
  room = '',
}) => {
  const firebase = useContext(FirebaseContext)
  const user = useContext(AuthUserContext)
  const fsBlock = firebase.useFsBlockInfo({ splinterGroup, blockId: block.id })
  return (
    <div>
      <Header
        block={block}
        splinterGroup={splinterGroup}
        isUnscheduled={block.id === 'unscheduled'}
        isJoint={!!partners[block.id]?.length}
        room={room}
        partners={partners}
        blocks={blocks}
        fsBlock={fsBlock}
      />
      {user.isAdmin ? (
        <HostTable
          splinterGroup={splinterGroup || 'unscheduled'}
          blockId={block.id}
          partners={partners}
          blocks={blocks}
          talkOrder={fsBlock.talkArr}
        ></HostTable>
      ) : (
        <AttendeeTable
          splinterGroup={splinterGroup || 'unscheduled'}
          blockId={block.id}
          partners={partners}
          talkOrder={fsBlock.talkArr}
        />
      )}
      <div className='w-full flex justify-between p-3'>
        <AddTalkModal
          splinterGroup={splinterGroup}
          firebase={firebase}
          user={user}
        />
        <AddQuestionModal
          splinterGroup={splinterGroup || 'unscheduled'}
          blockId={block.id}
          talkOrder={fsBlock.talkArr}
        />
      </div>
      <QuestionsTable
        splinterGroup={splinterGroup || 'unscheduled'}
        blockId={block.id}
        talkOrder={fsBlock.talkArr}
      />
    </div>
  )
}

const Header = ({
  block,
  splinterGroup,
  isUnscheduled,
  isJoint,
  room,
  partners,
  blocks,
  fsBlock,
}) => {
  const user = useContext(AuthUserContext)
  const f = useContext(FirebaseContext)
  return (
    <div>
      <h3 className='text-center p-3 text-3xl'>
        {!isUnscheduled ? (
          <span>
            {block.name} --{' '}
            <span className='text-amber-900'>{ROOMS[room].title}</span>{' '}
            {fsBlock.link && (
              <>
                {' -- '}
                <MeetingLink link={fsBlock.link} info={fsBlock.linkInfo} />
              </>
            )}
            {user.isAdmin && (
              <span>
                <>{' -- '}</>
                <AddVideoModal
                  thisBlockId={block.id}
                  thisFocusGroup={splinterGroup}
                  blocks={blocks}
                  partners={partners}
                  initialVideoLink={fsBlock.link}
                  initialVideoInfo={fsBlock.linkInfo}
                />
              </span>
            )}
            {!!(user.isAdmin && fsBlock?.talkArr?.length) && (
              <span>
                <>{' -- '}</>
                <Button
                  type='button'
                  variant='outline-secondary'
                  size='sm'
                  key={block.id + splinterGroup + 'downloadButton'}
                  onClick={() =>
                    f.downloadZipOfTalks({
                      talkIds: fsBlock.talkArr,
                      splinterGroup,
                      session: block.id,
                    })
                  }
                >
                  Download All
                </Button>
              </span>
            )}
          </span>
        ) : (
          block.name
        )}
      </h3>
      {!isUnscheduled && (
        <div>
          <h4 className='text-center text-xl'>
            {isJoint && 'JOINT SESSION with '}
            {isJoint &&
              partners[block.id].map((partner, i) => (
                <span key={i}>
                  {GROUPS[partner] ? (
                    <span className='text-blue-500 hover:text-red-500'>
                      <NavLink to={`/${ROUTES.FOCUSGROUPS}/${partner}`}>
                        {partner}
                      </NavLink>
                    </span>
                  ) : (
                    partner
                  )}
                  {i < partners[block.id].length - 1 && ', '}
                </span>
              ))}
          </h4>
        </div>
      )}
    </div>
  )
}

const AttendeeTable = ({
  splinterGroup,
  blockId,
  partners = [],
  talkOrder = [],
}) => {
  const talks = useContext(TalksContext)
  const user = useContext(AuthUserContext)
  return (
    <div>
      <div className='grid grid-cols-[min-content_1fr_repeat(2,min-content)] items-center'>
        <div className='contents mx-1'>
          <div className='font-bold pr-2'>Presenter</div>
          <div className='font-bold pr-2'>Title</div>
          <div className='cell'></div>
          <div className='cell'></div>
          <div className='col-span-5 border-t border-black bg-black'></div>
        </div>
        {talkOrder.length > 0 &&
          talkOrder.map((talkId, ind) => {
            const talk = talks[talkId] ?? {}
            return (
              <div
                className='contents'
                key={splinterGroup + blockId + ind + 'attendee'}
              >
                <div className='lg:whitespace-nowrap pr-2'>{talk.name}</div>
                <div className=''>{talk.title}</div>
                <div className=''>
                  <EditButton
                    talk={talk}
                    blockId={blockId}
                    partners={partners}
                    splinterGroup={splinterGroup}
                    className={'icon'}
                  />
                </div>
                <div className=''>
                  {talk.isPublic && talk.url ? (
                    <DownloadButton talk={talk} />
                  ) : (
                    talk.email === user.email && <DownloadButton talk={talk} />
                  )}
                </div>
                <div className='col-span-5 border-t border-neutral-300'></div>
              </div>
            )
          })}
      </div>
      {talkOrder.length === 0 && <p>No talks here!</p>}
    </div>
  )
}

const HostTable = ({
  splinterGroup,
  blockId,
  partners = [],
  blocks = [],
  talkOrder = [],
}) => {
  const talks = useContext(TalksContext)
  return (
    <div>
      <div className='grid grid-cols-[min-content_1fr_repeat(3,min-content)] items-center'>
        <div className='contents mx-1'>
          {/* col-span-5'> */}
          <div className='font-bold pr-2'>Presenter</div>
          <div className='font-bold'>Title</div>
          <div className='cell'></div>
          <div className='cell'></div>
          <div className='cell'></div>{' '}
          <div className='col-span-6 border-t border-black bg-black'></div>
        </div>
        {talkOrder.length > 0 &&
          talkOrder.map((talkId, ind) => {
            const talk = talks[talkId] ?? {}
            return (
              <div
                className='contents'
                key={splinterGroup + blockId + ind + 'host'}
              >
                <div className='lg:whitespace-nowrap pr-2'>{talk.name}</div>
                <div className=''>{talk.title}</div>
                <div className='flex'>
                  <EditButton
                    talk={talk}
                    blockId={blockId}
                    partners={partners}
                    splinterGroup={splinterGroup}
                    className={'icon'}
                  />
                  {talk.url && <DownloadButton talk={talk} />}
                </div>
                <div className='cell'>
                  <MoveButton
                    blocks={blocks}
                    partners={partners}
                    splinterGroup={splinterGroup}
                    currentBlock={blockId}
                    talkId={talk.id}
                  />
                </div>
                <div className='cell'>
                  <ReorderButton
                    blockId={blockId}
                    partners={partners}
                    splinterGroup={splinterGroup}
                    ind={ind}
                    maxInd={talkOrder.length - 1}
                  />
                </div>
                <div className='col-span-6 border-t border-neutral-300'></div>
              </div>
            )
          })}
      </div>
      {talkOrder.length === 0 && <p>No talks here!</p>}
    </div>
  )
}

const MoveButton = ({
  blocks = [],
  currentBlock = '',
  talkId = '',
  splinterGroup,
  partners = [],
}) => {
  const firebase = useContext(FirebaseContext)
  return (
    <DropdownButton title='Move' className='py-1'>
      {blocks
        .filter((block) => block.id !== currentBlock)
        .map((block) => {
          return (
            <Dropdown.Item
              as='button'
              name={block.id}
              key={block.id}
              onClick={(event) => {
                const newBlock = event.target.name
                firebase.moveTalk({
                  oldBlock: currentBlock,
                  oldSplinterGroups: [...partners[currentBlock], splinterGroup],
                  newBlock: newBlock,
                  newSplinterGroups: [...partners[newBlock], splinterGroup],
                  talkId,
                })
              }}
            >
              {block.name}
            </Dropdown.Item>
          )
        })}
    </DropdownButton>
  )
}

const EditButton = ({ talk, blockId, splinterGroup, partners, className }) => {
  const [showModal, setShowModal] = useState(false)
  const [name, setName] = useState(talk.name)
  const [title, setTitle] = useState(talk.title)
  const [slides, setSlides] = useState(null)
  const [isPublic, setIsPublic] = useState(talk.isPublic)
  const [isUploading, setIsUploading] = useState(false)
  const [error, setError] = useState('')
  const firebase = useContext(FirebaseContext)
  const user = useContext(AuthUserContext)
  const handleToggle = () => {
    setName(talk.name)
    setTitle(talk.title)
    setShowModal(!showModal)
  }
  return (
    <div>
      <span className={className} onClick={() => handleToggle()}>
        <PencilSquareIcon title='Edit' className='h-8 w-8' />
      </span>
      <Modal
        show={showModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={true}
        onHide={handleToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Edit talk
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={async (e) => {
              e.preventDefault()
              setIsUploading(true)
              try {
                await firebase.postTalk({
                  data: {
                    ...talk,
                    name,
                    title,
                    isPublic,
                  },
                  file: slides,
                  blockId,
                  splinterGroups: [splinterGroup, ...partners[blockId]],
                })
                setIsUploading(false)
                setShowModal(false)
              } catch (err) {
                console.error(err)
                setError(err)
                setIsUploading(false)
              }
            }}
          >
            <Form.Group controlId='title'>
              <Form.Label>Title</Form.Label>
              <Form.Control
                type='text'
                defaultValue={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId='name'>
              <Form.Label>Name</Form.Label>
              <Form.Control
                defaultValue={name}
                type='text'
                onChange={(e) => setName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type='file'
                id='slidesFile'
                label='Please upload your presentation.'
                onChange={(e) => setSlides(e.target.files[0])}
              />
            </Form.Group>
            <Form.Text className='text-muted'>
              You can reupload your files at any time. The files you had
              previously uploaded will be overwritten.
            </Form.Text>

            <br />
            <Form.Group controlId='public'>
              <Form.Check
                id='public'
                type='checkbox'
                checked={isPublic}
                onChange={() => setIsPublic(!isPublic)}
                label={`I wish to allow all attendees to this workshop access to my presentation. If unchecked, it will be made available to hosts only.`}
              />
            </Form.Group>
            <br />
            <Button variant='primary' type='submit'>
              Submit
            </Button>
            <img
              src={uploadingGif}
              style={{ display: isUploading ? '' : 'none' }}
              alt='Spinning circle around the word uploading'
            ></img>
            {error && <div>{error.message}</div>}
          </Form>
          {(user.isAdmin || talk.email === user.email) && (
            <div>
              <br />
              <br />
              <h3 className='left'>Do you want to delete this talk?</h3>
              <p>
                This deletes the talk permanently from the database. It deletes
                it from all focus group pages that it is currently on.
              </p>
              <p>
                You should only do this if it's a repeat or if the presenter
                made a mistake and will reupload their talk.
              </p>
              <Button
                variant='danger'
                onClick={() => {
                  firebase.deleteTalk({
                    splinterGroups: [...partners[blockId], splinterGroup],
                    blockId,
                    talkId: talk.id,
                    filePath: talk.file ?? '',
                  })
                  handleToggle()
                }}
              >
                I want to delete this talk
              </Button>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  )
}

const ReorderButton = ({
  blockId = '',
  ind,
  maxInd,
  partners,
  splinterGroup,
  ...props
}) => {
  const firebase = useContext(FirebaseContext)
  const splinterGroups = [...partners[blockId], splinterGroup]
  return (
    <div className='reorderContainer'>
      {/* \\style={{ width: '30px', paddingTop: '10px', paddingBottom: '10px' }}> */}
      {ind > 0 && (
        <UpButton
          onClickFunc={() =>
            firebase.reorderTalk({
              splinterGroups,
              blockId: blockId,
              topIndex: ind - 1,
            })
          }
        />
      )}
      {ind < maxInd && (
        // <div style={{ position: 'relative', top: 20 }}>
        <DownButton
          onClickFunc={() =>
            firebase.reorderTalk({
              splinterGroups,
              blockId: blockId,
              topIndex: ind,
            })
          }
        />
        // </div>
      )}
    </div>
  )
}

const UpButton = ({ onClickFunc, ...props }) => {
  return (
    <div className='h-4 group' onClick={() => onClickFunc()}>
      <div className='hidden group-hover:block'>
        <BsCaretUpFill size='30' />
      </div>
      <div className='block group-hover:hidden'>
        <BsCaretUp size='30' />
      </div>
    </div>
  )
}
const DownButton = ({ onClickFunc, ...props }) => {
  return (
    <div className='h-5 cursor-pointer group' onClick={() => onClickFunc()}>
      <div className='hidden group-hover:block'>
        <BsCaretDownFill size='30' />
      </div>
      <div className='block group-hover:hidden'>
        <BsCaretDown size='30' />
      </div>
    </div>
  )
}

const DownloadButton = ({ talk }) => {
  return (
    <a
      href={talk.url}
      target='_blank'
      rel='noopener noreferrer'
      download
      title='Download this presentation'
    >
      {
        <ArrowDownOnSquareIcon className='h-8 w-8 text-[#1e90ff] hover:text-[#8a2be2] cursor-pointer' />
      }
    </a>
  )
}

export default OneBlockComponent
