import React, { useEffect, useState } from 'react'
import Chip from '@mui/material/Chip'
import { FormGroup, Select, TextField, Typography } from '@mui/material'
import { compose } from 'react-recompose'
import { DropdownButton, Dropdown, Button } from 'react-bootstrap'
import { AuthUserContext, withAuthentication } from '../Session/'
import { POSTER_CATEGORIES } from '../../constants/posterSched.js'
import SlackLink from '../SlackLink'
//import posterOrder from '../../constants/posterOrder'
import {
  AiOutlineCloudUpload,
  AiOutlineProject,
  AiOutlineVideoCamera,
} from 'react-icons/ai'
import uploadingGif from '../../imgs/uploading.gif'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { FirebaseContext } from '../../server/Firebase'
import './filterBar.css'
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
} from '@mui/material'

const researchAreas = [
  { id: 'SWMI', display: 'Solar Wind - Magnetosphere Interaction' },
  { id: 'MPS', display: 'Magnetotail and Plasma Sheet' },
  { id: 'IMAG', display: 'Inner MAGnetosphere' },
  { id: 'MIC', display: 'Magnetosphere – Ionosphere Coupling' },
  { id: 'GSM', display: 'Global System Modeling' },
  { id: 'Other', display: 'Other' },
]
const posterDays = [
  { id: 'tue', display: 'Tuesday' },
  { id: 'thu', display: 'Thursday' },
]

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const FilterBar = () => {
  let [searchParams, setSearchParams] = useSearchParams()
  const ras = [...searchParams]
    .filter((e) => e[0] === 'researchArea')
    .map((e) => e[1])
  const [chosenResearchAreas, setChosenResearchAreas] = useState(ras)
  const [presenter, setPresenter] = useState(
    searchParams.get('presenter') || ''
  )
  const [title, setTitle] = useState(searchParams.get('title') || '')
  const files = [...searchParams]
    .filter((e) => e[0] === 'hasFiles')
    .map((e) => e[1])
  const [uploadedFiles, setUploadedFiles] = useState(files)
  const presentingDays = [...searchParams]
    .filter((e) => e[0] === 'day')
    .map((e) => e[1])
  const [days, setDays] = useState(presentingDays)
  const [number, setNumber] = useState(searchParams.get('number'))

  useEffect(() => {
    const params = {}
    if (chosenResearchAreas) params.researchArea = chosenResearchAreas
    if (presenter) params.presenter = presenter
    if (uploadedFiles) params.hasFiles = uploadedFiles
    if (title) params.title = title
    if (days) params.day = days
    if (number) params.number = number

    setSearchParams(params)
  }, [chosenResearchAreas, presenter, uploadedFiles, title, days, number])

  const handleResearchSelect = (e) => {
    const {
      target: { value },
    } = e
    setChosenResearchAreas(typeof value === 'string' ? value.split(',') : value)
  }
  const handleUploadedFilesSelect = (e) => {
    const {
      target: { value },
    } = e
    setUploadedFiles(typeof value === 'string' ? value.split(',') : value)
  }

  const handlePresenter = (e) => {
    const {
      target: { value },
    } = e
    setPresenter(value)
  }
  const handleTitle = (e) => {
    const {
      target: { value },
    } = e
    setTitle(value)
  }
  const handleDaysSelect = (e) => {
    const {
      target: { value },
    } = e
    setDays(typeof value === 'string' ? value.split(',') : value)
  }
  const handleNumber = (e) => {
    const {
      target: { value },
    } = e
    setNumber(value)
  }
  const handleClearAll = (e) => {
    setChosenResearchAreas([])
    setPresenter('')
    setUploadedFiles([])
    setTitle('')
    setDays([])
    setNumber('')
  }

  return (
    <div className='flex flex-wrap justify-center items-center'>
      <FormControl sx={{ m: 1, width: 300 }}>
        <InputLabel>Research Area</InputLabel>
        <Select
          id='researchArea'
          multiple
          labelId='researchArea-label'
          value={chosenResearchAreas}
          onChange={handleResearchSelect}
          input={<OutlinedInput label='Research Areas' />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          className='uploaded-files-input'
        >
          {researchAreas.map((ra) => {
            return (
              <MenuItem key={ra.id} value={ra.id}>
                <Checkbox checked={chosenResearchAreas.includes(ra.id)} />
                <ListItemText
                  primary={ra.display}
                  primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <TextField
        id='presenter'
        label='Presenter'
        variant='outlined'
        sx={{ m: 1 }}
        value={presenter}
        onChange={handlePresenter}
        className='filter'
      />
      <TextField
        id='title'
        label='Title'
        variant='outlined'
        sx={{ m: 1 }}
        value={title}
        onChange={handleTitle}
        className='filter'
      />
      {/* <FormControl sx={{ m: 1, width: '10em' }} className='filter'>
        <InputLabel>Day</InputLabel>
        <Select
          id='presenting-day'
          multiple
          labelId='presenting-day'
          value={days}
          onChange={handleDaysSelect}
          input={<OutlinedInput label='Day' />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {posterDays.map((d) => {
            return (
              <MenuItem key={d.id} value={d.id}>
                <Checkbox checked={days.includes(d.id)} />
                <ListItemText
                  primary={d.display}
                  primaryTypographyProps={{ style: { whiteSpace: 'normal' } }}
                />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl> */}
      {/* <TextField
        id='number'
        label='Number'
        variant='outlined'
        sx={{ m: 1 }}
        value={number}
        onChange={handleNumber}
        className='filter'
      /> */}
      <FormControl sx={{ m: 1, width: '10em' }} className='filter'>
        <InputLabel>Uploaded Files</InputLabel>
        <Select
          id='uploaded-files'
          multiple
          labelId='uploaded-files-label'
          value={uploadedFiles}
          onChange={handleUploadedFilesSelect}
          input={<OutlinedInput label='Uploaded files' />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          styles={{ width: 100 }}
        >
          {['PDF', 'Video'].map((fileType) => {
            return (
              <MenuItem key={fileType} value={fileType}>
                <Checkbox checked={uploadedFiles.includes(fileType)} />
                <ListItemText primary={fileType} />
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
      <Button
        variant='outline-primary'
        type='reset'
        onClick={handleClearAll}
        className='h-[3em]'
      >
        Clear All
      </Button>
    </div>
  )
}
export default FilterBar
