import React, { useState, useContext } from 'react'
import { AuthUserContext, withAuthentication } from '../Session'
import { Table, Button, Form, Modal } from 'react-bootstrap'
import { PLENARY, PLENARY_PRESENTATIONS } from '../../constants/plenary'
import SlackLink from '../SlackLink'
import VideoLink from '../VideoLink'
import { BsFileEarmarkArrowDown, BsFileEarmarkPlus } from 'react-icons/bs'
import { subcommittee } from './subcommittee'
// import { Room, RoomAndZoom, Zoom } from '../Room'
import { Link } from 'react-router-dom'
import uploadingGif from '../../imgs/uploading.gif'
import { FirebaseContext } from '../../server/Firebase'
import AddVideoModal from '../SplinterGroup/AddVideoModal'
import MeetingLink from '../MeetingLink'

const chrisZoom = 'https://unh.zoom.us/j/98142163586'

const Plenary = () => {
  const blocks = Object.keys(PLENARY)
  const firebase = useContext(FirebaseContext)
  const fbPresentations = firebase.useTalks({ splinterGroup: 'plenary' })
  return (
    <div>
      <h1 className='text-center py-3 text-5xl'>Plenary Sessions</h1>

      <p className='sm:p-2'>Subcommittee: {subcommittee}</p>
      <h3>{blocks.date}</h3>
      <div className='sm:px-2'>
        <PlenTable blocks={blocks} fbPresentations={fbPresentations} />
      </div>
    </div>
  )
}
export const PlenTable = ({ blocks, fbPresentations, ...props }) => {
  return (
    <Table bordered hover size='lg'>
      <tbody>
        {blocks.map((block) => (
          <Block
            block={block}
            key={block}
            ind={block.name}
            fbPresentations={fbPresentations}
          />
        ))}
      </tbody>
    </Table>
  )
}

const Block = ({ block, ind, fbPresentations, ...props }) => {
  const blockObj = PLENARY[block]
  const presIds = blockObj.presentations
  const partners = {}
  const firebase = useContext(FirebaseContext)
  const authUser = useContext(AuthUserContext)
  for (const block of Object.values(PLENARY)) {
    partners[[block.id]] = []
  }
  const fsBlock = firebase.useFsBlockInfo({
    splinterGroup: 'plenary',
    blockId: blockObj.id,
  })
  let body = presIds.map((id, i) => {
    return i === 0 ? (
      <tr key={id}>
        <td rowSpan={presIds.length} width='200px'>
          <strong>{blockObj.date}</strong>
          <br />
          <strong>{blockObj.time + ' PT'}</strong>
          <br />
          <div>
            <h3 className={'left'}>
              <div>
                <SlackLink url={blockObj.slack} />
              </div>
              {block.room}
            </h3>
          </div>
          <div>
            {blockObj.hosts && (
              <>
                <strong>Chair: </strong>
                {blockObj.hosts.join(', ')}
              </>
            )}
          </div>
          <div>
            {fsBlock.link && (
              <MeetingLink link={fsBlock.link} info={fsBlock.linkInfo} />
            )}
          </div>
          {authUser.isAdmin && (
            <AddVideoModal
              thisBlockId={blockObj.id}
              thisFocusGroup={'plenary'}
              blocks={Object.values(PLENARY)}
              partners={partners}
              initialVideoLink={fsBlock.link}
              initialVideoInfo={fsBlock.linkInfo}
            />
          )}
          <div>
            <Button
              type='button'
              variant='primary'
              size='sm'
              key={presIds[0] + 'downloadButton'}
              onClick={() =>
                firebase.downloadZipOfTalks({
                  talkIds: presIds,
                  splinterGroup: 'plenary',
                  session: presIds[0].split('').slice(0, -1).join(''),
                })
              }
            >
              Download presentations
            </Button>
          </div>
        </td>
        <td>{PresentationInfo(PLENARY_PRESENTATIONS[id])}</td>
        <td width={'40px'}>
          <UploadButton
            presentation={
              fbPresentations[id]
                ? fbPresentations[id]
                : PLENARY_PRESENTATIONS[id]
            }
            id={id}
          />
        </td>
        <td width={'40px'}>
          {fbPresentations[id] && fbPresentations[id].url && (
            <DownloadButton url={fbPresentations[id].url} />
          )}
        </td>
      </tr>
    ) : (
      <tr key={i}>
        <td>{PresentationInfo(PLENARY_PRESENTATIONS[id])}</td>
        <td>
          <UploadButton
            presentation={
              fbPresentations[id]
                ? fbPresentations[id]
                : PLENARY_PRESENTATIONS[id]
            }
            id={id}
          />
        </td>
        <td>
          {fbPresentations[id] && fbPresentations[id].url && (
            <DownloadButton url={fbPresentations[id].url} />
          )}
        </td>
      </tr>
    )
  })
  return body
}

const PresentationInfo = (pres) => (
  <div>
    {pres.link ? (
      <Link to={pres.link}>
        <strong>{pres.title}</strong>
      </Link>
    ) : (
      <strong>{pres.title}</strong>
    )}
    {pres.subtitle && (
      <span>
        <strong>:</strong> {pres.subtitle}
      </span>
    )}
    <br />
    {pres.host && <div>Speaker: {pres.host}</div>}
    <div>{pres.panelists && `Panelists: ${pres.panelists.join(', ')}`}</div>
    {pres.moderators && `Moderators: ${pres.moderators.join(', ')}`}
  </div>
)

const DownloadButton = ({ url }) => {
  return (
    <a
      href={url}
      download
      target='_blank'
      rel='noopener noreferrer '
      style={{ cursor: 'pointer' }}
      title='Download this presentation'
    >
      {<BsFileEarmarkArrowDown size='30' />}
    </a>
  )
}

const UploadButton = ({ presentation, id }) => {
  const firebase = useContext(FirebaseContext)
  const [showModal, setShowModal] = useState(false)
  const [slides, setSlides] = useState(null)
  const [verify, setVerify] = useState(false)
  const [isUploading, setIsUploading] = useState(false)
  const handleToggle = () => setShowModal(!showModal)
  return (
    <div>
      <BsFileEarmarkPlus
        size='30'
        onClick={() => handleToggle()}
        title='Upload'
        style={{ cursor: 'pointer' }}
      />
      <Modal
        show={showModal}
        size='lg'
        aria-labelledby='contained-modal-title-vcenter'
        centered
        backdrop={true}
        onHide={handleToggle}
      >
        <Modal.Header closeButton>
          <Modal.Title id='contained-modal-title-vcenter'>
            Upload Poster Files
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={async (e) => {
              e.preventDefault()
              setIsUploading(true)
              await firebase.postStudentOrPlenaryTalk({
                group: 'plenary',
                data: presentation,
                file: slides,
                id,
              })
              setIsUploading(false)
              setShowModal(false)
            }}
          >
            <Form.Group controlId='verify'>
              <Form.Check
                id='verify'
                type='checkbox'
                checked={verify}
                onChange={() => setVerify(!verify)}
                label={`I confirm that I am ${
                  presentation.host
                    ? presentation.host
                    : presentation.panelists
                    ? presentation.panelists.join(' or ')
                    : ''
                }.`}
              />
            </Form.Group>
            <Form.Group>
              <Form.Control
                type='file'
                id='posterFile'
                label='Please upload your presentation. This will allow everyone to access and download this file'
                accept='.ppt, .pptx, .pdf, .zip'
                onChange={(e) => setSlides(e.target.files[0])}
              />
            </Form.Group>
            You can reupload your files at any time. The files you had
            previously uploaded will be overwritten.
            <br />
            <br />
            <Button
              variant='primary'
              type='submit'
              disabled={!(verify && !!slides)}
            >
              Submit
            </Button>
            <img
              src={uploadingGif}
              style={{ display: isUploading ? '' : 'none' }}
              alt='Spinning circle around the word uploading'
            ></img>
          </Form>
          <Button
            variant='danger'
            onClick={() => {
              firebase.deletePlenary({ talkId: id })
            }}
          >
            I would like to delete the uploaded presentation.
          </Button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default withAuthentication(Plenary)
