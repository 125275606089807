import { PLENARY } from './plenary'

export const BLOCKS = {
  student: {
    dayInfo: {
      date: 'Sunday, June 23',
      dayName: 'student',
    },
    0: {
      groups: [],
      rooms: ['GG'],
      time: '8:00am-3:30pm',
      name: 'Student Session',
      slack: 'gemworkshop.slack.com/app_redirect?channel=0-student',
      done: false,
    },
    1: {
      order: 1,
      groups: [],
      rooms: ['RAMS'],
      time: '4:30pm-6:30pm',
      name: 'Steering Committee Meeting',
    },
    2: {
      order: 2,
      groups: [],
      rooms: ['A'],
      time: '6:00pm-7:30pm',
      name: 'Ice Breaker',
    },
  },
  monday: {
    dayInfo: {
      date: 'Monday, June 24',
      dayName: 'monday',
    },
    0: {
      order: 0,
      groups: ['plenary'],
      time: '8:00am-10:00am',
      name: 'Monday Plenary Session',
      date: 'Monday, June 24',
      slack: PLENARY.monday.slack,
      day: 'monday',
      rooms: ['S3'],
      videos: [],
      id: 'plen1',
    },
    1: {
      order: 1,
      groups: ['SCIMM', 'KiTS', 'GIC'],
      rooms: ['S3', 'GG', 'L'],
      time: '10:30am-12:00pm',
      name: 'Monday Session 1',
      id: 'monday-1',
    },
    2: {
      order: 2,
      groups: [],
      rooms: [],
      time: '12:50pm - 1:20pm',
      name: 'Climate Survey Townhall',
      id: 'climate',
    },
    3: {
      order: 3,
      groups: ['CP', 'RX', 'GIC'],
      rooms: ['S3', 'GG', 'L'],
      time: '1:30pm-3:00pm',
      name: 'Monday Session 2',
      videos: [],
      id: 'monday-2',
    },
    4: {
      order: 4,
      groups: ['CP', 'RX', 'MMV'],
      rooms: ['S3', 'GG', 'L'],
      time: '3:30pm-5:00pm',
      name: 'Monday Session 3',
      id: 'monday-3',
    },
    5: {
      groups: [],
      name: 'Student Dinner',
      time: '6:00pm-8:30pm',
      rooms: [''],
    },
  },
  tuesday: {
    dayInfo: {
      date: 'Tuesday, June 25',
      dayName: 'tuesday',
    },
    0: {
      order: 0,
      groups: ['plenary'],
      time: '8:00am-10:00am',
      name: 'Tuesday Plenary Session',
      slack: PLENARY.tuesday.slack,
      day: 'tuesday',
      rooms: ['S3'],
      id: 'plen2',
    },
    1: {
      order: 1,
      groups: ['RB', 'MESO', 'CPMP'],
      rooms: ['S3', 'GG', 'L'],
      time: '10:30am-12:00pm',
      name: 'Tuesday Session 1',
      id: 'tuesday-1',
    },
    2: {
      order: 2,
      groups: [],
      time: '12:00pm-1:30pm',
      rooms: ['CPB'],
      name: 'Agency Office Hours',
    },
    3: {
      order: 3,
      groups: [['CPMP', 'RB'], 'MPEC'],
      rooms: ['S3', 'GG'],
      time: '1:30pm-3:00pm',
      name: 'Tuesday Session 2',
      id: 'tuesday-2',
    },
    4: {
      order: 4,
      groups: ['RB', 'MPEC', ['CPMP', 'RX']],
      rooms: ['S3', 'GG', 'L'],
      time: '3:30pm-5:00pm',
      name: 'Tuesday Session 3',
      id: 'tuesday-3',
    },
    5: {
      order: 5,
      groups: [],
      rooms: ['S1'],
      time: '6:00pm-8:30pm',
      name: 'Poster Session',
    },
  },
  wednesday: {
    dayInfo: {
      date: 'Wednesday, June 14',
      dayName: 'wednesday',
    },
    0: {
      order: 0,
      groups: ['plenary'],
      time: '8:00am-10:00am',
      name: 'Wednesday Plenary Session',
      slack: PLENARY.wednesday.slack,
      day: 'wednesday',
      rooms: ['S3'],
      id: 'plen3',
    },
    1: {
      order: 1,
      groups: [['KiTS', 'RX'], 'MPEC', 'CPMP'],
      rooms: ['S3', 'GG', 'L'],
      time: '10:30am-12:00pm',
      name: 'Wednesday Session 1',
      id: 'wednesday-1',
    },
    2: {
      order: 2,
      groups: [],
      rooms: ['L'],
      time: '12:00pm-1:30pm',
      name: 'Student Rep Candidate Presentations',
    },
    3: {
      order: 3,
      groups: ['KiTS', ['MESO', 'MPEC', 'GIC'], 'RB'],
      rooms: ['S3', 'GG', 'L'],
      time: '1:30pm-3:00pm',
      name: 'Wednesday Session 2',
      id: 'wednesday-2',
    },
    4: {
      order: 4,
      groups: [],
      rooms: ['L'],
      time: '3:30pm-5:00pm',
      name: 'SBCCI - DEI Event',
      id: 'wednesday-3',
    },
    5: {
      order: 5,
      groups: [],
      rooms: ['S3'],
      time: '6:00pm-8:30pm',
      name: 'Banquet',
    },
  },
  thursday: {
    dayInfo: {
      date: 'Thursday, June 27',
      dayName: 'thursday',
    },
    0: {
      order: 0,
      groups: ['plenary'],
      time: '8:00am-10:00am',
      name: 'Thursday Plenary Session',
      slack: PLENARY.thursday.slack,
      day: 'thursday',
      rooms: ['S3'],
      id: 'plen4',
    },
    1: {
      order: 1,
      groups: ['MLGEM', 'MESO', ['MMV', 'GIC']],
      rooms: ['S3', 'GG', 'L'],
      time: '10:30am-12:00pm',
      name: 'Thursday Session 1',
      id: 'thursday-1',
    },
    2: {
      order: 2,
      groups: [],
      rooms: ['A'],
      time: '12:50pm-1:20pm',
      name: 'GEMEE Lunch',
    },
    3: {
      order: 3,
      groups: [['SCIMM', 'CP'], ['KiTS', 'MESO'], 'BSH'],
      rooms: ['S3', 'GG', 'L'],
      time: '1:30pm-3:00pm',
      name: 'Thursday Session 2',
      id: 'thursday-2',
    },
    4: {
      order: 4,
      groups: [['MLGEM', 'RB', 'SCIMM', 'CP'], 'MESO', 'BSH'],
      rooms: ['S3', 'GG', 'L'],
      time: '3:30pm-5:00pm',
      name: 'Thursday Session 3',
      id: 'thursday-3',
    },
    5: {
      order: 5,
      groups: [],
      rooms: ['S1'],
      time: '6:00pm-8:30pm',
      name: 'Poster Session',
      date: 'Thursday, June 23',
      id: 'thursday-4',
    },
    6: {
      order: 6,
      groups: [],
      rooms: [''],
      time: 'Late',
      name: 'SBCCI - Queer Beers',
      id: 'thursday-5',
    },
  },
  friday: {
    dayInfo: {
      date: 'Friday, June 28',
      dayName: 'friday',
    },
    0: {
      order: 0,
      groups: ['plenary'],
      time: '8:00am-10:00am',
      name: 'Friday Plenary Session',
      slack: PLENARY.friday.slack,
      rooms: ['S3'],
      id: 'plen5',
    },
    1: {
      order: 1,
      groups: ['MLGEM', ['MESO', 'MPEC', 'CP']],
      rooms: ['S3', 'GG'],
      time: '10:30am-12:00pm',
      name: 'Friday Session 1',
      id: 'friday-1',
    },
    2: {
      order: 2,
      groups: ['MLGEM', 'GMAG', 'SPEDAS'],
      rooms: ['S3', 'GG', 'L'],
      time: '10:30am-12:00pm',
      name: 'Friday Session 2',
      id: 'friday-2',
    },
    3: {
      order: 3,
      groups: ['MLGEM', 'GMAG', 'SPEDAS'],
      rooms: ['S3', 'GG', 'L'],
      time: '10:30am-12:00pm',
      name: 'Friday Session 3',
      id: 'friday-3',
    },
  },
}
